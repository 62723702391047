import React from "react";
import { SectionProps } from "../../@types/Section";
import Typer from "../../Components/Typer";
import FadeIn from "../../Components/FadeIn";
import FancyImage from "../../Components/FancyImage";
import { getStrapiMedia } from "../../strapi/media";

interface Props extends SectionProps {
    HalfHeroSize: boolean,
    HeroImage: any,
    Tagline: string | null,
    Title: string | null,
    hasIntro?: boolean
}

const NonProductHero: React.FC<Props> = (props) => {
    return (
        <>
            <div className={`content-container ${(!props.hasIntro && props.HeroImage && props.HeroImage.data) ? 'no-intro-hero' : ''}`}>
                <div className="_2-col hero">
                    <div className="col">
                        <h1>
                            <Typer text={props.Title ? props.Title : ''} />
                        </h1>
                    </div>
                    <div className="hero-subtitle-container col">
                        <FadeIn classNames="hero-subtitle">
                            <h3>{props.Tagline ? props.Tagline : ''}</h3>
                        </FadeIn>
                    </div>
                </div>
                {
                    props.HeroImage && props.HeroImage.data ?
                    <div className={`hero-container ${props.HalfHeroSize ? 'half' : ''}`}>
                        <FancyImage
                            classNames='hero-image'
                            fill
                            alt={props.HeroImage.data.attributes.caption}
                            src={getStrapiMedia(props.HeroImage)}
                            height={props.HeroImage.data.attributes.height}
                            width={props.HeroImage.data.attributes.width}
                        />
                    </div>: null
                }
            </div>
        </>
    )
}

export default NonProductHero