import React from "react";
import FadeIn from "../../Components/FadeIn";
import { SectionProps } from "../../@types/Section";
import Typer from "../../Components/Typer";
import Button from "../../Components/Button";
import NewTabIcon from '../../Components/assets/new-tab.svg';
import FancyImage from "../../Components/FancyImage";
import styles from './Hero.module.scss';
import { getStrapiMedia } from "../../strapi/media";

interface Props extends SectionProps {
    HeroTitle: string | null,
    HeroDescription: string | null,
    PrimaryLink: {
        LinkTitle: string | null,
        LinkDestination: string | null
    }
    SecondaryLink: {
        LinkTitle: string | null,
        LinkDestination: string | null
    }
    IsFullWidthHeroOrnament: boolean
    HeroBackground: any
    HeroOrnament: any
}

const Hero: React.FC<Props> = (props) => {
    return (
        <>
            {
                props.HeroBackground && props.HeroBackground.data ?
                    <FancyImage
                        classNames="hero-image dark-loader"
                        fill
                        alt={props.HeroBackground.data.attributes.caption}
                        src={getStrapiMedia(props.HeroBackground)}
                        height={props.HeroBackground.data.attributes.height}
                        width={props.HeroBackground.data.attributes.width}
                        shouldFade
                    /> : null
            }
            <div className="content-container">
                {
                    props.IsFullWidthHeroOrnament && props.HeroOrnament && props.HeroOrnament.data ? 
                        <FancyImage
                            classNames={styles['full-width-hero-ornament']}
                            fill
                            shouldFade
                            alt={props.HeroOrnament.data.attributes.caption}
                            src={getStrapiMedia(props.HeroOrnament)}
                            height={props.HeroOrnament.data.attributes.height}
                            width={props.HeroOrnament.data.attributes.width}
                        /> : null
                }
                <div className={props.IsFullWidthHeroOrnament ? styles['full-width-hero-content'] : ''}>
                    <div className="_x-col-container">
                        <div className="_2-col hero full-width">
                            <div className="description-container col">
                                <div>
                                    <h1><Typer text={props.HeroTitle ? props.HeroTitle : ''} /></h1>
                                </div>
                                <div>
                                    <FadeIn>
                                        <>
                                            {
                                                props.HeroDescription ?
                                                    <div className="paragraphs-container big-text description">
                                                        {
                                                            props.HeroDescription.split('\n\n').map((s, idx) =>
                                                                idx === 0 ?
                                                                    <div className="paragraph" key={`description-${idx}`}>
                                                                        <h2 className="normalize-text">{s}</h2>
                                                                    </div>
                                                                    : <div className="paragraph">
                                                                        <p>
                                                                            {s}
                                                                        </p>
                                                                    </div>
                                                            )
                                                        }
                                                    </div>
                                                    : null
                                            }
                                            {
                                                props.PrimaryLink || props.SecondaryLink ?
                                                    <div className="grouped-buttons-container horizontal">
                                                        {
                                                            props.PrimaryLink && props.PrimaryLink.LinkDestination && props.PrimaryLink.LinkTitle ?
                                                                <Button
                                                                    isLink
                                                                    isLinkWrapper
                                                                    trackConversion
                                                                    openNewTab
                                                                    href={props.PrimaryLink.LinkDestination}
                                                                    renderIcon={<NewTabIcon />}
                                                                    type="white"
                                                                    text={props.PrimaryLink.LinkTitle}
                                                                /> : null
                                                        }
                                                        {
                                                            props.SecondaryLink && props.SecondaryLink.LinkDestination && props.SecondaryLink.LinkTitle ?
                                                                <Button
                                                                    isLink
                                                                    isLinkWrapper
                                                                    trackConversion
                                                                    openNewTab
                                                                    href={props.SecondaryLink.LinkDestination}
                                                                    renderIcon={<NewTabIcon />}
                                                                    type="secondary"
                                                                    text={props.SecondaryLink.LinkTitle}
                                                                /> : null
                                                        }
                                                    </div> : null
                                            }

                                        </>
                                    </FadeIn>
                                </div>
                            </div>
                            {
                                props.HeroOrnament && props.HeroOrnament.data && !props.IsFullWidthHeroOrnament ?
                                    <div className="image-container col">
                                        <FancyImage
                                            shouldFade
                                            alt={props.HeroOrnament.data.attributes.caption}
                                            src={getStrapiMedia(props.HeroOrnament)}
                                            height={props.HeroOrnament.data.attributes.height}
                                            width={props.HeroOrnament.data.attributes.width}
                                        />
                                    </div> : <div className="col empty" />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
};

export default Hero;