import React from "react";
import FadeIn from "../../Components/FadeIn";
import { SectionProps } from "../../@types/Section";
import Typer from "../../Components/Typer";
import CheckmarkIcon from '../../Components/assets/checkmark.svg';

interface Props extends SectionProps {
    index: number,
    Title: string | null,
    Classifier: {
        ClassifierNumber: string | null,
        ClassifierText: string | null,
        id: number
    }[],
    Row: {
        IsHeading?: boolean,
        Column: {
            id: number,
            Text: string | null,
            Type: 'Text' | 'Checked'
            TextClassifier: string | null
        }[]
    }[]
}

const ChecklistTable: React.FC<Props> = (props) => {
    const checkmarkElement = <div className="checkmark-container"><CheckmarkIcon /></div>;
    return (
            <div className="_2-col-titled">
                <div className="col">
                    <h3 className="h2">
                        { props.Title ? <Typer vizTrigger text={props.Title} /> : null }
                    </h3>
                </div>
                <div className="col">
                    <FadeIn>
                        <table className="editions-table">
                            <thead>
                                <tr>
                                    {
                                        props.Row && props.Row.length ?props.Row.map((r, idx) => {
                                            if (r.IsHeading) {
                                                const headingRow: React.ReactNode[] = [];
                                                r.Column.forEach((c, idx2) => {
                                                    headingRow.push(<th key={`ct-rh-${props.index}-${idx}-${idx2}`}>{c.Text ? c.Text : null}</th>)
                                                })
                                                return headingRow
                                            }
                                            return null
                                        }) : null
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.Row && props.Row.length ? props.Row.map((r, idx) => {
                                        if (!r.IsHeading) {
                                            const tableRow: React.ReactNode[] = [];
                                            r.Column.forEach((c, idx2) => {
                                                if (c.Type === 'Text' && c.Text) {
                                                    if (c.TextClassifier) {
                                                        tableRow.push(<td key={`ct-r-${props.index}-${idx}-d-${idx2}`}><div className="checklist-limited">{c.Text}<sup className="limited-superscript">{c.TextClassifier}</sup></div></td>);
                                                    } else {
                                                        tableRow.push(<th scope='row' key={`ct-r-${props.index}-${idx}-d-${idx2}`}>{c.Text}</th>);
                                                    }
                                                } else if (c.Type === 'Checked') {
                                                    tableRow.push(<td key={`ct-r-${props.index}-${idx}-d-${idx2}`}>{checkmarkElement}</td>);
                                                } else {
                                                    tableRow.push(<td key={`ct-r-${props.index}-${idx}-d-${idx2}`}></td>);
                                                }
                                            })
                                            if (tableRow.length) {
                                                return <tr key={`ct-r-${props.index}-${idx}`}>{tableRow}</tr>
                                            }
                                            return null
                                        }
                                        return null
                                    }) : null
                                }
                            </tbody>
                        </table>
                    </FadeIn>
                    {
                        props.Classifier && props.Classifier.length ?
                        <FadeIn>
                            <div className="limited-definition paragraphs-container">
                                {
                                    props.Classifier.map((c, idx) => {
                                        return (
                                            <p key={`ct-c-${props.index}-${idx}`} className="paragraph">
                                                <sup className="limited-superscript">{c.ClassifierNumber}</sup>{` ${c.ClassifierText}`}
                                            </p>
                                        )
                                    })
                                }
                            </div>
                        </FadeIn> : null
                    }
                </div>
            </div>
    )
};

export default ChecklistTable;