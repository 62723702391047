import React from "react";
import FadeIn from "../../Components/FadeIn";
import Splitter from "../../Components/Splitter";
import { SectionProps } from "../../@types/Section";
import Typer from "../../Components/Typer";

interface Props extends SectionProps {
    index: number,
    Items: {
        Description: string
        Title: string
    }[]
    SectionTitle: string,
}

const SimpleList: React.FC<Props> = (props) => {
    const itemsCount = props.Items.length;

    return (
        <>
            {
                props.SectionTitle ?
                    <>
                        <h3 className="h1 standalone-title">
                            <Typer vizTrigger text={props.SectionTitle} />
                        </h3>
                        <Splitter vizTrigger />
                    </> : null
            }
            
            {
                props.Items && props.Items.length ? props.Items.map((item, idx) => {
                    const listItem = [
                        <div className="_2-col" key={`simplelist-${props.index}-${idx}`}>
                            <div className="col">
                                <h3 className="h2">
                                    <Typer vizTrigger text={item.Title} />
                                </h3>
                            </div>
                            <div className="col">
                                {
                                    item.Description ?
                                        <FadeIn>
                                            <div className="paragraphs-container big-text">
                                                {
                                                    item.Description.split('\n\n').map((s, idx) =>
                                                        <div className="paragraph" key={`simplelist-pl-description-${props.index}-${idx}`}>
                                                            <p>
                                                                {s}
                                                            </p>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </FadeIn>
                                        : null
                                }
                            </div>
                        </div>
                    ];
                    if (idx !== itemsCount - 1) {
                        listItem.push(<Splitter vizTrigger key={`${props.index}-${idx}-simplelist-splitter`} />);
                    }
                    return <React.Fragment key={`sl-${idx}`}>{listItem}</React.Fragment>
                }) : null
            }
        </>
    )
};

export default SimpleList;