import React from "react";
import { SectionProps } from "../../@types/Section";
import Typer from "../../Components/Typer";
import { PageSection } from "../../@types/PageSection";

interface Props extends SectionProps {
    Sections: PageSection[]
}

const PageSectionsList: React.FC<Props> = (props) => {
    return (
        <section id="hero-subnav" className="hero-wrapper">
            <div className="content-wrapper hero-subnav-wrapper">
                <div className="content-container">
                    <ul className="hero-subnav">
                        {
                            props.Sections && props.Sections.length ? props.Sections.map((section, idx) => 
                                <li key={`page-sections-list-${idx}`}>
                                    <div className="hero-subnav-number"><b><Typer text={idx.toString().padStart(2, '0')} /></b></div>
                                    <span><Typer text={section.title} /></span>
                                </li>
                            ) : null
                        }
                    </ul>
                </div>
            </div>
        </section>
    )
};

export default PageSectionsList;