import type { NextPage } from 'next';
import { fetchAPI } from '../strapi/api';
import TwoColumnNested from '../PageSections/Components/TwoColumnNested';
import TwoColumnStandard from '../PageSections/Components/TwoColumnStandard';
import PageEnd from '../PageSections/Components/PageEnd';
import Splitter from '../Components/Splitter';
import ChecklistTable from '../PageSections/Components/ChecklistTable';
import Intro from '../PageSections/Components/Intro';
import PageSectionsList from '../PageSections/Components/PageSectionsList';
import Hero from '../PageSections/Components/Hero';
import PageNav from '../Components/PageNav';
import React from 'react';
import { PageSection } from '../@types/PageSection';
import SimpleList from '../PageSections/Components/SimpleList';
import PhotoList from '../PageSections/Components/PhotoList';
import NonProductHero from '../PageSections/Components/NonProductHero';
import ContactForm from '../PageSections/Components/ContactForm';
import ProductList from '../PageSections/Components/ProductList';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import getConfig from "next/config";
import RichTextWrapper from '../PageSections/Components/RichTextWrapper';
const { publicRuntimeConfig } = getConfig();

const DynamicGame = dynamic(() => import('../Components/Game/game'), {
    ssr: false
})

const Page: NextPage = ({ pageContent, pageUrl }: any) => {
    const sectionsEl: PageSection[] = [];
    const elRefs: React.RefObject<HTMLDivElement>[] = [];
    const heroRef = React.createRef<HTMLDivElement>();
    const [activeNo, setActiveNo] = React.useState(0);

    let hasIntro = false;
    if (pageContent) {
        
        let isStandAlone = false;
        if (pageContent.IntroductionRow && pageContent.Section1 && pageContent.Section2 && pageContent.Section3 && pageContent.Section4) {
            isStandAlone = 1 === pageContent.IntroductionRow.length + pageContent.Section1.length + pageContent.Section2.length + pageContent.Section3.length + pageContent.Section4.length
        }

        Object.keys(pageContent).forEach((section, idx) => {
            if (section.indexOf('IntroductionSection') > -1 && pageContent[section]) {
                //This must go first.
                const sectionEl: React.ReactNode[] = [];
                sectionEl.unshift(
                    <Intro
                        key={`IntroductionSection-container-${idx}`}
                        Row_1={pageContent[section].Row_1}
                        Row_2={pageContent[section].Row_2}
                        Row_3_FullsizeVideo={pageContent[section].Row_3_FullsizeVideo}
                    />
                );
                if (sectionEl.length) {
                    hasIntro = true;
                    elRefs.unshift(React.createRef<HTMLDivElement>());
                    sectionsEl.unshift(
                        {
                            title: pageContent[section].SectionTitle,
                            anchorId: 's-intro',
                            jsx: <section
                                id={`s-intro`}
                                ref={elRefs[elRefs.length - 1]}
                            >
                                {sectionEl}
                            </section>
                        }
                    );
                }
            }
            if (section.indexOf('Section') === 0) {
                const sectionEl: React.ReactNode[] = [];
                const sectionLen = pageContent[section].length;
                if (pageContent[section].length) {
                    let title = pageContent[section][0].SectionTitle;
                    pageContent[section].forEach((subSection: any, idx2: number) => {
                        const subSectionEl: React.ReactNode[] = [];
                        let skip = false;
                        if (subSection.__component === 'page-components.product-list-section') {
                            subSectionEl.push(
                                <ProductList
                                    key={`${section}-${idx}-${idx2}`}
                                    index={idx}
                                    List={subSection.List}
                                />
                            )
                        }
                        if (subSection.__component === 'page-components.two-column-section-nested') {
                            subSectionEl.push(
                                <TwoColumnNested
                                    key={`${section}-${idx}-${idx2}`}
                                    SectionTitle={subSection.SectionTitle}
                                    Items={subSection.Item}
                                    index={idx}
                                />
                            )
                        }
                        if (subSection.__component === 'page-components.two-column-paragraphs') {
                            subSectionEl.push(
                                <TwoColumnStandard
                                    key={`${section}-${idx}-${idx2}`}
                                    LeftColumn={subSection.LeftColumn}
                                    RightColumn={subSection.RightColumn}
                                    index={idx}
                                />
                            )
                        }
                        if (subSection.__component === 'checklist-table.call-table') {
                            if (subSection.TableContents) {
                                subSectionEl.push(
                                    <ChecklistTable
                                        key={`${section}-${idx}-${idx2}`}
                                        Classifier={subSection.TableContents.Classifier}
                                        Row={subSection.TableContents.Row}
                                        Title={subSection.TableContents.Title}
                                        index={idx}
                                    />
                                )
                            } else {
                                skip = true;
                            }
                        }
                        if (subSection.__component === "page-components.simple-list-section") {
                            subSectionEl.push(
                                <SimpleList
                                    key={`${section}-${idx}-${idx2}`}
                                    index={idx}
                                    SectionTitle={subSection.SectionTitle}
                                    Items={subSection.List}
                                />
                            )
                        }
                        if (subSection.__component === "page-components.bio-section") {
                            subSectionEl.push(
                                <PhotoList
                                    key={`${section}-${idx}-${idx2}`}
                                    index={idx}
                                    SectionTitle={subSection.SectionTitle}
                                    Items={subSection.ListItem}
                                />
                            )
                        }
                        if (subSection.__component === "page-components.contact-row") {
                            subSectionEl.push(
                                <ContactForm
                                    key={`${section}-${idx}-${idx2}`}
                                    index={idx}
                                    formName={`${section}-${idx}-${idx2}`}
                                    Endpoint={subSection.FormContents.Endpoint}
                                    Description={subSection.Description}
                                    FallbackEmail={subSection.FallbackEmail}
                                    Dropdown={subSection.FormContents.Dropdown}
                                    Input={subSection.FormContents.Input}
                                />
                            )
                        }
                        if (subSection.__component === "page-components.publication") {
                            subSectionEl.push(
                                <RichTextWrapper
                                    key={`${section}-${idx}-${idx2}`}
                                    Content={subSection.content}
                                    isStandAlone
                                />
                            )
                        }
                        if (!skip) {
                            if (idx2 !== sectionLen - 1) {
                                subSectionEl.push(<Splitter key={`${section}-${idx}-${idx2}-splitter`} vizTrigger />)
                            }
                            sectionEl.push(
                                <div className="content-container padded-left" key={`${section}-${idx}-${idx2}-container`}>
                                    {subSectionEl}
                                </div>
                            )
                        }
                    });
                    if (sectionEl.length) {
                        elRefs.push(React.createRef<HTMLDivElement>());
                        sectionsEl.push({
                            title,
                            anchorId: `s-${idx}`,
                            jsx: <>
                                <div className="content-container" key={`${section}-${idx}-splitter-container`}>
                                    <Splitter vizTrigger isThick text={String(sectionsEl.length).padStart(2, '0')} />
                                </div>
                                <section
                                    id={`s-${idx}`}
                                    ref={elRefs[elRefs.length - 1]}
                                >
                                    {sectionEl}
                                </section>
                            </>
                        });
                    }
                }
            }
        });

        if (pageContent['PageEnd']) {
            const sectionEl: React.ReactNode[] = [];
            const title = pageContent['PageEnd'].SectionTitle ? pageContent['PageEnd'].SectionTitle : '';
            sectionEl.push(
                <div className="content-container padded-left" key={`PageEnd-container`}>
                    <PageEnd
                        Content={pageContent['PageEnd'].Content}
                        Link={pageContent['PageEnd'].Link}
                        LinkTitle={pageContent['PageEnd'].LinkTitle}
                    />
                </div>
            );
            if (sectionEl.length) {
                elRefs.push(React.createRef<HTMLDivElement>());
                sectionsEl.push({
                    title,
                    anchorId: 's-pageEnd',
                    jsx: <>
                        <div className="content-container" key={`PageEnd-splitter-container`}>
                            <Splitter vizTrigger isThick text={String(sectionsEl.length).padStart(2, '0')} />
                        </div>
                        <section
                            id={`s-pageEnd`}
                            ref={elRefs[elRefs.length - 1]}
                        >
                            {sectionEl}
                        </section>
                    </>
                });
            }
        }
    }

    return (
        <>
            {
                pageContent ? pageContent.Metadata ? 
                    //use supplied metadata
                    <Head>
                        {pageContent.Metadata.Title ? <title key="title">{pageContent.Metadata.Title}</title> : null}
                        {pageContent.Metadata.Title ? <meta name="twitter:title" content={pageContent.Metadata.Title} key="twname" /> : null}
                        {pageContent.Metadata.Title ? <meta property="og:title" content={pageContent.Metadata.Title} key="ogtitle" /> : null}
                        {pageContent.Metadata.Description ? <meta name="description" content={pageContent.Metadata.Description} key="desc" /> : null}
                        {pageContent.Metadata.Description ? <meta name="og:description" content={pageContent.Metadata.Description} key="ogdesc" /> : null}
                        {pageContent.Metadata.Description ? <meta name="twitter:description" content={pageContent.Metadata.Description} key="twdesc" /> : null}
                        <link rel="canonical" href={`${publicRuntimeConfig.NEXT_PUBLIC_SITE_URL}/${pageUrl}`} key="canonical" />
                    </Head> :
                    //fallback to default
                    null :
                    //page doesn't exist. use 404
                    <Head>
                        <title key="title">{'404 Page - Kayhan Space'}</title>
                        <meta name="twitter:title" content={'404 Page - Kayhan Space'} key="twname" />
                        <meta property="og:title" content={'404 Page - Kayhan Space'} key="ogtitle" />
                        <meta name="description" content={`Even our astrodynamicists cannot find the page you are looking for.`} key="desc" />
                        <meta name="og:description" content={`Even our astrodynamicists cannot find the page you are looking for.`} key="ogdesc" />
                        <meta name="twitter:description" content={`Even our astrodynamicists cannot find the page you are looking for.`} key="twdesc" />
                        <link rel="canonical" href={`${publicRuntimeConfig.NEXT_PUBLIC_SITE_URL}/error`} key="canonical" />
                    </Head>
            }
            {
                pageContent ? 
                    <main key={`${pageContent.PageType}-${pageContent.slug}`}>
                        {
                            pageContent.Hero && pageContent.Hero.length && pageContent.Hero[0].__component === "page-components.page-hero" ?
                                <section id="hero" className="hero-container" ref={heroRef}>
                                    <div className="hero-container">
                                        <Hero
                                            IsFullWidthHeroOrnament={pageContent.Hero[0].IsFullWidthHeroOrnament}
                                            HeroTitle={pageContent.Hero[0].HeroTitle}
                                            HeroDescription={pageContent.Hero[0].HeroDescription}
                                            HeroBackground={pageContent.Hero[0].HeroBackground}
                                            HeroOrnament={pageContent.Hero[0].HeroOrnament}
                                            PrimaryLink={pageContent.Hero[0].PrimaryLink}
                                            SecondaryLink={pageContent.Hero[0].SecondaryLink}
                                        />
                                    </div>
                                    {
                                        pageContent.PageType === "products" ?
                                            <section id="hero-subnav">
                                                <PageSectionsList Sections={sectionsEl} />
                                            </section> : null
                                    }
                                </section>
                                : null
                        }
                        {
                            pageContent.Hero && pageContent.Hero.length && pageContent.Hero[0].__component === "page-components.non-product-hero" ?
                                <section ref={heroRef}>
                                    <NonProductHero
                                        HalfHeroSize={pageContent.Hero[0].HalfHeroSize}
                                        HeroImage={pageContent.Hero[0].HeroImage}
                                        Title={pageContent.Hero[0].Title}
                                        Tagline={pageContent.Hero[0].Tagline}
                                        hasIntro={hasIntro}
                                    />
                                </section>
                                : null
                        }
                        <div className="content-wrapper">
                            {
                                sectionsEl.length > 3 ?
                                    <PageNav
                                        pageSections={sectionsEl}
                                        elRefs={elRefs}
                                        activationRef={heroRef}
                                        setActiveNo={setActiveNo}
                                        activeNo={activeNo}
                                    /> : null
                            }

                            <div className="sections-container">
                                {sectionsEl.map((sectionEl, idx) => {
                                    return <React.Fragment key={`section-${idx}`}>{sectionEl.jsx}</React.Fragment>
                                })}
                            </div>
                        </div>
                    </main> :
                    <DynamicGame />
            }
        </>
    )
}

export async function getServerSideProps(context: any) {
    const slug = context.query.slug;
    if (slug.length === 1) {
        slug.unshift('root');
    }
    const res = await fetchAPI(`/dynamic-pages?populate=deep&filters[PageType][$eqi]=${slug[0]}&filters[slug][$eqi]=${slug[1]}`);
    if (res && res.data && res.data.length > 0) {
        const data = await res.data[0];

        //This is to detect any tables within sections and then fetching the table data.
        for (const key in data.attributes) {
            if (key.toLowerCase().trim().indexOf('section') > -1) {
                const component = data.attributes[key]
                if (Array.isArray(component)) {
                    for (const c of component) {
                        if (c.__component && c.__component.indexOf('checklist-table.call-table') > -1) {
                            const table = await fetchAPI(`/checklist-tables/${c.TableID}?populate=deep`);
                            if (table) {
                                c.SectionTitle = table.data.attributes.Title;
                                c.TableContents = table.data.attributes;
                            }
                        }
                        if (c.__component && c.__component.indexOf("page-components.contact-row") > -1) {
                            const form = await fetchAPI(`/contact-forms/${c.ContactFormID}?populate=deep`);
                            if (form) {
                                c.FormContents = form.data.attributes;
                            }
                        }
                    }
                }
            }
        }
        if (data && data.attributes && Object.keys(data.attributes).length) {
            return {
                props: {
                    pageContent: {
                        ...data.attributes
                    },
                    pageUrl: context.query.slug.length ? context.query.slug.join('/') : ''
                }
            }
        }
    }
    
    return {
        props: {
            pageContent: null
        }
    }
}


export default Page
