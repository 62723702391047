import React from "react";
import { SectionProps } from "../../@types/Section";
import LinkWrapper from "../../Components/Link";

interface Props extends SectionProps {
    Content: string
    Link?: string | null
    LinkTitle?: string | null
}

const PageEnd: React.FC<Props> = (props) => {
    return (
        <div className="_2-col">
            <div className="col mobile-no-padding-bottom">
                {
                    props.Content ?
                        <div className="paragraphs-container h3">
                            {
                                props.Content.split('\n\n').map((s, idx) =>
                                    idx === 0 ?
                                        <div className="paragraph" key={`description-${idx}`}>
                                            <h4 className="normalize-text">{s}</h4>
                                        </div>
                                        : <div className="paragraph" key={`description-${idx}`}>
                                            <p>
                                                {s}
                                            </p>
                                        </div>
                                )
                            }
                        </div>
                        : null
                }
                {
                    props.Link && props.LinkTitle ?
                    <>
                            <br />
                            <br />
                            <LinkWrapper
                                text={props.LinkTitle}
                                href={props.Link}
                                classNames="h3"
                                trackConversion
                            />
                    </> : null
                }
            </div>
            <div className="col empty"></div>
        </div>
    )
};

export default PageEnd;