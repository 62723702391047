import React from "react";
import FadeIn from "../../Components/FadeIn";
import Splitter from "../../Components/Splitter";
import { SectionProps } from "../../@types/Section";
import FancyImage from "../../Components/FancyImage";
import LinkWrapper from "../../Components/Link";
import { getStrapiMedia } from "../../strapi/media";
import ZoomableVideo from "../../Components/ZoomableVideo";

interface Props extends SectionProps {
    Row_1: {
        RightCol_Link: string | null,
        RightCol_Text: string | null,
        RightCol_Link_Title: string | null
    },
    Row_2: {
        RightCol_Text: string | null,
        RightCol_Text_Size: 'bigBody' | 'body',
        LeftCol_Text?: string | null,
        LeftCol_Media?: any
    },
    Row_3_FullsizeVideo: any
}

const Intro: React.FC<Props> = (props) => {
    return(
        <>
            <div className="content-container padded-left">
                <div className="_2-col">
                    <div className="col empty" />
                    {
                        props.Row_1 ? 
                            <div className="col">
                                <FadeIn>
                                    <>
                                        {
                                            props.Row_1.RightCol_Text ?
                                                <div className="paragraphs-container h3">
                                                    {
                                                        props.Row_1.RightCol_Text.split('\n\n').map((s, idx) =>
                                                            idx === 0 ?
                                                                <div className="paragraph" key={`intro-description-rc-${idx}`}>
                                                                    <h3 className="normalize-text">{s}</h3>
                                                                </div>
                                                                : <div className="paragraph" key={`intro-description-rc-${idx}`}>
                                                                    <p>
                                                                        {s}
                                                                    </p>
                                                                </div>
                                                        )
                                                    }
                                                </div>
                                                : null
                                        }
                                        {
                                            props.Row_1.RightCol_Link && props.Row_1.RightCol_Link_Title ?
                                                <>
                                                    <br />
                                                    <br />
                                                    <LinkWrapper
                                                        text={props.Row_1.RightCol_Link_Title}
                                                        classNames="h3"
                                                        href={props.Row_1.RightCol_Link}
                                                    />
                                                </> : null
                                        }
                                    </>
                                </FadeIn>
                            </div> : <div className="col empty" />
                    }
                </div>
                { props.Row_2 ? <Splitter vizTrigger /> : null }
            </div>
            
               {
                props.Row_2 ?
                    <div className="content-container padded-left">
                        <div className="_2-col">
                            {
                                props.Row_2.LeftCol_Media ?
                                    getStrapiMedia(props.Row_2.LeftCol_Media).indexOf('mp4') > -1 ?
                                        <div className="col">
                                            <ZoomableVideo classNames='col-video' src={getStrapiMedia(props.Row_2.LeftCol_Media)} />
                                        </div>
                                        :
                                        <div className="col">
                                            <FancyImage
                                                classNames="screenshot-image"
                                                alt={props.Row_2.LeftCol_Media.data.attributes.caption}
                                                zoomable
                                                vizTrigger
                                                height={props.Row_2.LeftCol_Media.data.attributes.height}
                                                width={props.Row_2.LeftCol_Media.data.attributes.width}
                                                src={getStrapiMedia(props.Row_2.LeftCol_Media)}
                                            />
                                        </div>
                                    :
                                    null
                            }
                            {
                                props.Row_2.LeftCol_Text ?
                                    <div className="col">
                                        <FadeIn>
                                            <h3>
                                                {props.Row_2.LeftCol_Text}
                                            </h3>
                                        </FadeIn>
                                    </div> : null
                            }
                            {
                                !props.Row_2.LeftCol_Text && !props.Row_2.LeftCol_Media ? <div className="col empty" /> : null
                            }
                            {
                                props.Row_2.RightCol_Text ?
                                    <div className="col">
                                        <FadeIn>
                                            <div className="paragraphs-container">
                                                {
                                                    props.Row_2.RightCol_Text.split('\n\n').map((s, idx) =>
                                                        <p key={`intro-description-lc-${idx}`} className={`paragraph ${props.Row_2.RightCol_Text_Size === 'bigBody' ? 'big-text' : ''}`}>
                                                            {s}
                                                        </p>
                                                    )
                                                }
                                            </div>
                                        </FadeIn>
                                    </div> : <div className="col empty" />
                            }
                        </div>
                    </div>
                    : null
               }
            {
                props.Row_3_FullsizeVideo && props.Row_3_FullsizeVideo.data ? 
                    <div className="content-container">
                        <div className="video-background-container">
                            <video
                                muted={true}
                                autoPlay={true}
                                loop={true}
                                playsInline={true}
                                controls={false}
                            >
                                <source src={getStrapiMedia(props.Row_3_FullsizeVideo)} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                : null
            }
        </>
    )
};

export default Intro;