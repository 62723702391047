import React from "react";
import FadeIn from "../../Components/FadeIn";
import { SectionProps } from "../../@types/Section";
import FancyImage from "../../Components/FancyImage";
import LinkWrapper from "../../Components/Link";
import { getStrapiMedia } from "../../strapi/media";

interface Column {
    ColumnLink: string | null,
    ColumnLinkTitle: string | null,
    ColumnMedia: any,
    ColumnText: string | null,
    Type: 'media' | 'text'
}

interface Props extends SectionProps {
    index: number,
    LeftColumn: Column,
    RightColumn: Column,
    SectionTitle?: string | null
}

const TwoColumnStandard: React.FC<Props> = (props) => {
    return (
        <div className="_2-col">
            <div className="col">
                {
                    props.LeftColumn && props.LeftColumn.Type === 'text' ?
                        <FadeIn>
                            <>
                                {
                                    props.LeftColumn.ColumnText && props.LeftColumn.ColumnText.length ?
                                        <div className="paragraphs-container big-text">
                                            {
                                                props.LeftColumn.ColumnText.split('\n\n').map((s, idx) =>
                                                    <p key={`tcs-lc-description-${props.index}-${idx}`} className={`paragraph`}>
                                                        {s}
                                                    </p>
                                                )
                                            }
                                        </div>
                                        : null
                                }
                                {
                                    props.LeftColumn.ColumnLink && props.LeftColumn.ColumnLinkTitle ?
                                        <LinkWrapper
                                            href={props.LeftColumn.ColumnLink}
                                            classNames="p-link big-text"
                                            text={props.LeftColumn.ColumnLinkTitle}
                                        /> : null
                                }
                            </>
                        </FadeIn> :
                        props.LeftColumn.ColumnMedia && props.LeftColumn.ColumnMedia.data ? 
                        <FancyImage
                            height={props.LeftColumn.ColumnMedia.data.attributes.height}
                            width={props.LeftColumn.ColumnMedia.data.attributes.width}
                            classNames="screenshot-image"
                            alt={props.LeftColumn.ColumnMedia.data.attributes.caption}
                            vizTrigger
                            zoomable
                            src={getStrapiMedia(props.LeftColumn.ColumnMedia)}
                        /> : null
                }
            </div>
            <div className="col">
                {
                    props.RightColumn && props.RightColumn.Type === 'text' ?
                        <FadeIn>
                            <>
                                {
                                    props.RightColumn.ColumnText ? 
                                        <div className="paragraphs-container big-text">
                                            {
                                                props.RightColumn.ColumnText.split('\n\n').map((s, idx) =>
                                                    <p key={`tcs-rc-description-${props.index}-${idx}`} className={`paragraph`}>
                                                        {s}
                                                    </p>
                                                )
                                            }
                                        </div>
                                    : null
                                }
                                {
                                    props.RightColumn.ColumnLink && props.RightColumn.ColumnLinkTitle ?
                                        <LinkWrapper
                                            href={props.RightColumn.ColumnLink}
                                            classNames="p-link big-text"
                                            text={props.RightColumn.ColumnLinkTitle}
                                        /> : null
                                }
                            </>
                        </FadeIn> :
                        props.RightColumn.ColumnMedia && props.RightColumn.ColumnMedia.data ?
                        <FancyImage
                            height={props.RightColumn.ColumnMedia.data.attributes.height}
                            width={props.RightColumn.ColumnMedia.data.attributes.width}
                            classNames="screenshot-image"
                            alt={props.RightColumn.ColumnMedia.data.attributes.caption}
                            vizTrigger
                            zoomable
                            src={getStrapiMedia(props.RightColumn.ColumnMedia)}
                        /> : null
                }
            </div>
        </div>
    )
};

export default TwoColumnStandard;