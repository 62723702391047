import React from "react";
import FadeIn from "../../Components/FadeIn";
import Splitter from "../../Components/Splitter";
import { SectionProps } from "../../@types/Section";
import Typer from "../../Components/Typer";

interface Props extends SectionProps {
    index: number,
    Items: {
        Content: string
        Title: string
    }[]
    SectionTitle: string,
}

const TwoColumnNested: React.FC<Props> = (props) => {
    const itemsEl: React.ReactNode[] = [];
    if (props.Items) {
        const itemsCount = props.Items.length;
        for (let i = 0; i < itemsCount; i += 2) {
            const isNotLast = (i + 2 < itemsCount);
            const itemsSubEl: React.ReactNode[] = [];
            let lastColClassName = '';

            if (props.Items[i + 1]) {
                itemsSubEl.push(
                    <div className={`col`} key={`col-${i+1}`}>
                        <h4>{props.Items[i + 1].Title}</h4>
                        {
                            props.Items[i + 1].Content ?
                                <div className="paragraphs-container">
                                    {
                                        props.Items[i + 1].Content.split('\n\n').map((s, idx) =>
                                            <p key={`tcn-description-${props.index}-${i + 1}+${idx}`} className={`paragraph`}>
                                                {s}
                                            </p>
                                        )
                                    }
                                </div>
                                : null
                        }
                    </div>
                )
            } else {
                lastColClassName = 'mobile-no-padding-bottom';
                itemsSubEl.push(<div className="col empty ornament" key={`col-${i+1}`} />);
            }

            if (props.Items[i]) {
                itemsSubEl.unshift(
                    <div className={`col ${lastColClassName}`} key={`col-${i}`}>
                        <h4>{props.Items[i].Title}</h4>
                        {
                            props.Items[i].Content ?
                                <div className="paragraphs-container">
                                    {
                                        props.Items[i].Content.split('\n\n').map((s, idx) =>
                                            <p key={`tcn-description-${props.index}-${i}+${idx}`} className={`paragraph`}>
                                                {s}
                                            </p>
                                        )
                                    }
                                </div>
                                : null
                        }
                    </div>
                );
            }

            if (isNotLast) {
                itemsEl.push(
                    <React.Fragment key={`${props.SectionTitle}-${props.index}-${i}`}>
                        <div className={`_2-col ${isNotLast ? 'mobile-no-padding-bottom' : ''}`}>
                            {itemsSubEl}
                        </div>
                        <Splitter hideOnMobile />
                    </React.Fragment>
                )
            } else {
                itemsEl.push(
                    <React.Fragment key={`${props.SectionTitle}-${props.index}-${i}`}>
                        <div className={`_2-col`}>
                            {itemsSubEl}
                        </div>
                    </React.Fragment>
                )
            }
        }
    }

    return (
        <div className="_2-col-titled">
            <div className="col">
                <h3 className="h2">
                    <Typer vizTrigger text={props.SectionTitle} />
                </h3>
            </div>
            <div className="col">
                <FadeIn>
                    <React.Fragment>
                        {itemsEl}
                    </React.Fragment>
                </FadeIn>
            </div>
        </div>
    )
};

export default TwoColumnNested;