import React from 'react';
import ExitIcon from './assets/exit.svg';
import MagnifyIcon from './assets/magnify.svg';
import { ThemeContext, ThemeContextType } from '../pages/_app';
import Button from './Button';
import Router from 'next/router';

interface Props {
    src: string;
    classNames?: string;
    href?: string;
}

const ZoomableVideo: React.FC<Props> = (props) => {
    let setLockScroll: undefined | ((bool: boolean) => void);
    const themeContext = React.useContext(ThemeContext) as ThemeContextType;
    if (themeContext) {
        setLockScroll = themeContext.setLockScroll;
    }
    let classNames = "screenshot-video";
    if (props.href) {
        classNames += ` link-image`;
    } else {
        classNames += ` zoomable-media`;
    }
    if (props.classNames) classNames += ` ${props.classNames}`;

    const [isZoomed, setIsZoomed] = React.useState(false);

    React.useEffect(() => {
        if (setLockScroll) {
            setLockScroll(isZoomed);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isZoomed]);

    React.useEffect(() => {
        return () => {
            setIsZoomed(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div
                className={classNames}
                onClick={() => {
                    if (props.href) {
                        Router.push(props.href);
                    } else {
                        setIsZoomed(true);
                    }
                }}
            >
                {
                    props.href ? null : <Button classNames='zoom-indicator' renderIcon={<MagnifyIcon />} type="secondary" />
                }
                <video
                    muted
                    controls={false}
                    playsInline={true}
                    loop={true}
                    autoPlay={true}
                    className={classNames}
                >
                    <source src={props.src} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            {isZoomed ?
                <div className="zoomed-media-container">
                    <div className="zoomed-media-controls-container">
                        <Button
                            label={'Close zoomed-in video'}
                            type='secondary'
                            renderIcon={<ExitIcon />}
                            callback={() => setIsZoomed(false)}
                        />
                    </div>
                    <div className="zoomed-media-content">
                        <video
                            muted
                            controls={true}
                            playsInline={true}
                            loop={true}
                            autoPlay={true}
                        >
                            <source src={props.src} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
                : null}
        </>
    )
};

export default ZoomableVideo;

