import React from "react";
import { SectionProps } from "../../@types/Section"
import Button from "../../Components/Button";
import FadeIn from "../../Components/FadeIn";
import FancyImage from "../../Components/FancyImage";
import Splitter from "../../Components/Splitter";
import Typer from "../../Components/Typer";
import RightArrowIcon from "../../Components/assets/arrow--right.svg";
import { getStrapiMedia } from "../../strapi/media";
import styles from './ProductList.module.scss';
import ZoomableVideo from "../../Components/ZoomableVideo";

interface Props extends SectionProps {
    index: number,
    List: {
        Description: string | null,
        Link: string | null,
        LinkTitle: string | null,
        ProductMedia: any,
        Title: string | null,
        id: number
    }[]
}

const ProductList: React.FC<Props> = (props) => {
    const sectionEls = props.List.map((l, i) =>
        <React.Fragment key={`productlist-${props.index}-${i}`}>
            <div className="_2-col">
                <div className="col">
                    <h3 className="h2">
                        <Typer vizTrigger text={l.Title ? l.Title : 'Product'} />
                    </h3>
                </div>
                <div className="col">
                    <FadeIn>
                        <>
                            <p className="big-text">
                                {l.Description}
                            </p>
                            {
                                l.ProductMedia && l.ProductMedia.data ?
                                    getStrapiMedia(l.ProductMedia).indexOf('mp4') > -1 ?
                                    <ZoomableVideo
                                        classNames='col-video'
                                        src={getStrapiMedia(l.ProductMedia)}
                                        href={l.Link ? l.Link : '/'}
                                    /> :
                                    <FancyImage
                                        classNames="screenshot-image"
                                        height={l.ProductMedia.data.attributes.height}
                                        width={l.ProductMedia.data.attributes.width}
                                        href={l.Link ? l.Link : '/'}
                                        alt={l.ProductMedia.data.attributes.caption ? l.ProductMedia.data.attributes.caption : 'Caption'}
                                        vizTrigger
                                        src={getStrapiMedia(l.ProductMedia)}
                                    /> : null 
                            }
                            <Button
                                isLink
                                classNames={styles['product-link-button']}
                                href={l.Link ? l.Link : '/'}
                                text={l.LinkTitle ? l.LinkTitle : 'Learn more'}
                                renderIcon={<RightArrowIcon />}
                            />
                        </>
                    </FadeIn>
                </div>
            </div>
            <Splitter vizTrigger />
        </React.Fragment>
    );

    return (
        <div className={styles['products-container']}>
            {sectionEls}
        </div>
    )
}

export default ProductList;